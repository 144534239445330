import https from '../utils/https'

// 获取客户列表
export function fetchCustomerlist(params) {
  return https.fetchPost('/api/backstage/customer/list', params)
}

// 获取客户信息接口
export function fetchCustomerDetail(params) {
  return https.fetchGet('/api/backstage/customer/info', params)
}

// 创建客户接口
export function createCustomer(params) {
  return https.fetchPost('/api/backstage/customer/create', params)
}

// 更新客户属性接口
export function updateCustomer(params) {
  return https.fetchPost('/api/backstage/customer/update', params)
}