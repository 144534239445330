<style lang="less" scoped>
#customer-detail {
  .tab-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .tab-top-line {
    width: 100%;
    height: 2px;
    background-color: #F7F8FA;
    margin-top: 12px;
  }
  .customer-info {
    margin: 30px 40px 60px 40px;
    ::v-deep .ant-form-item {
      margin-bottom: 15px ;
      height: 46px;
    }
    ::v-deep .ant-form-item-label {
      width: 100px ;
    }
    ::v-deep .ant-form-item-label,
    label {
      color: #666666;
      font-size: 16px ;
    }
    ::v-deep .ant-form-item-label > label:after {
      content: "" ;
    }
    ::v-deep .ant-form-item-control-wrapper {
      display: inline-block ;
      margin-left: 10px ;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-calendar-picker,
    ::v-deep .ant-calendar-picker-input {
      height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-cascader-picker,
    ::v-deep .ant-cascader-input {
      height: 46px;
      width: 350px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-children, input {
      height: 46px;
      line-height: 46px;
    }
    ::v-deep .ant-form-item-control-wrapper,
    ::v-deep .ant-form-item-control,
    ::v-deep .ant-form-item-children,
    ::v-deep .ant-select-enabled,
    ::v-deep .ant-select-selection,
    ::v-deep .ant-select-selection__rendered {
      height: 46px;
      line-height: 46px;
    }
    .flex-vw {
      display: flex;
      ::v-deep .ant-form-item-control-wrapper,
      ::v-deep .ant-form-item-control,
      ::v-deep .ant-form-item-children,
      ::v-deep .ant-cascader-picker,
      ::v-deep .ant-cascader-input {
        width: 200px;
      } 
      .cus-left {
        margin-left: 80px;
      }
    }
    .tips-div {
      margin-left: 25px;
      margin-top: 30px;
      display: flex;
      .tips-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
      }
      .tips-list {
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        display: inline-block;
        margin-left: 20px;
      }
    }
  }
  .save-button {
    width: 480px;
    height: 60px;
    margin-top: 50px;
    margin-left: 30px;
    border-radius: 4px;
    background-color: #447df5;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="customer-detail" id="customer-detail">
    <div class="tab-top">
      <table-title />
    </div>
    <div class="tab-top-line"></div>
    <div class="customer-info">
      <template>
        <a-form-model ref="infoForm" :model="form">
          <a-form-model-item label="客户名称" prop="customer_name" ref="customer_name">
            <a-input v-model="form.customer_name" placeholder="请输入客户名称"/>
          </a-form-model-item>
          <a-form-model-item label="客户简称" prop="abbreviated_name" ref="abbreviated_name">
            <a-input v-model="form.abbreviated_name" placeholder="请输入客户简称"/>
          </a-form-model-item>
          <a-form-model-item label="客户全称" prop="full_name" ref="full_name">
            <a-input v-model="form.full_name" placeholder="请输入客户全称"/>
          </a-form-model-item>
          <a-form-model-item label="客户标识" prop="customer_identifier" ref="customer_identifier">
            <a-input v-model="form.customer_identifier" placeholder="请输入客户标识"/>
          </a-form-model-item>
          <a-form-model-item label="客户密钥" prop="password" ref="password">
            <a-input v-model="form.password" placeholder="请输入客户密钥"/>
          </a-form-model-item>
          <a-form-model-item label="qquid对应参数" prop="qquid_parameter_name" ref="qquid_parameter_name">
            <a-input v-model="form.qquid_parameter_name" placeholder="请输入qquid对应参数"/>
          </a-form-model-item>
          <a-form-model-item label="menu对应参数" prop="menu_parameter_name" ref="menu_parameter_name">
            <a-input v-model="form.menu_parameter_name" placeholder="请输入menu对应参数"/>
          </a-form-model-item>
          <a-form-model-item label="完成状态标识" prop="complete_flag" ref="complete_flag">
            <a-input v-model="form.complete_flag" placeholder="请输入完成状态标识"/>
          </a-form-model-item>
          <a-form-model-item label="甄别状态标识" prop="recognize_flag" ref="recognize_flag">
            <a-input v-model="form.recognize_flag" placeholder="请输入甄别状态标识"/>
          </a-form-model-item>
          <a-form-model-item label="配额满状态标识" prop="quota_exhausted_flag" ref="quota_exhausted_flag">
            <a-input v-model="form.quota_exhausted_flag" placeholder="请输入配额满状态标识"/>
          </a-form-model-item>
        </a-form-model>
        <a-form-model-item label="优先级" prop="priority" ref="priority">
          <a-select v-model="form.priority" placeholder="请选择货币类型" style="width:100px">
            <a-select-option :key="1">A级</a-select-option>
            <a-select-option :key="2">B级</a-select-option>
            <a-select-option :key="3">C级</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="C状态链接" prop="complete_callback_url" ref="complete_callback_url">
          <a-input v-model="form.complete_callback_url" placeholder="请输入C状态链接"/>
        </a-form-model-item>
        <a-form-model-item label="S状态链接" prop="recognize_callback_url" ref="recognize_callback_url">
          <a-input v-model="form.recognize_callback_url" placeholder="请输入S状态链接"/>
        </a-form-model-item>
        <a-form-model-item label="Q状态链接" prop="quota_exhausted_callback_url" ref="quota_exhausted_callback_url">
          <a-input v-model="form.quota_exhausted_callback_url" placeholder="请输入Q状态链接"/>
        </a-form-model-item>
        <div class="tips-div">
          <div class="tips-title">备注说明</div>
          <div class="tips-list">
            <p>1.所有参数只能是字母数字的组合，不区分大小写，长度不超过16个字符</p>
            <br>
            <p>2.不能有空格,&等特殊字符</p>
            <br>
            <p>3.如果客户返回参数时，参数名称不是qquid，请让技术另外增加endlink页面</p>
          </div>
        </div>
      </template>
      <a-button type="save" class="save-button" @click="onSubmit" :loading="loading">保存</a-button>
    </div>
  </div>
</template>

<script>
import { createCustomer, fetchCustomerDetail, updateCustomer } from "@/api/customer"
export default {
  name: "CustmerDetail",
  components: {
    "table-title": resolve =>
      require(["@/components/table/TableTitle.vue"], resolve),
  },
  data() {
    return {
      loading: false,
      customerId: undefined,
      form: {
        customer_name: undefined,
        abbreviated_name: undefined,
        full_name: undefined,
        customer_identifier: undefined,
        password: undefined,
        qquid_parameter_name: "qquid",
        menu_parameter_name: "menu",
        complete_flag: "C",
        recognize_flag: "S",
        quota_exhausted_flag: "Q",
        priority: 1,
        complete_callback_url: undefined,
        recognize_callback_url: undefined,
        quota_exhausted_callback_url: undefined,
      },
    };
  },
  created() {
    this.customerId = this.$route.query.customerId;
    this.initData();
  },
  methods: {
    initData() {
      if (this.customerId != undefined) {
        this.getCustomerDetail()
      }
    },
    /// 获取用户信息
    async getCustomerDetail() {
      try {
        let res = await fetchCustomerDetail({
          customer_id: this.customerId
        });
        this.form.customer_name = res.customer_name;
        this.form.abbreviated_name = res.abbreviated_name;
        this.form.full_name = res.full_name;
        this.form.customer_identifier = res.customer_identifier;
        this.form.password = res.password;
        this.form.qquid_parameter_name = res.qquid_parameter_name;
        this.form.menu_parameter_name = res.menu_parameter_name;
        this.form.complete_flag = res.complete_flag;
        this.form.recognize_flag = res.recognize_flag;
        this.form.quota_exhausted_flag = res.quota_exhausted_flag;
        this.form.priority = res.priority;
        this.form.complete_callback_url = res.complete_callback_url;
        this.form.recognize_callback_url = res.recognize_callback_url;
        this.form.quota_exhausted_callback_url = res.quota_exhausted_callback_url;
      } catch (error) {
        console.log(error)
      }
    },
    onSubmit() {
      if (!this.form.customer_name) {
        this.$message.error("请输入客户名称");
        return
      }
      if (!this.form.abbreviated_name) {
        this.$message.error("请输入客户简称");
        return
      }
      if (!this.form.full_name) {
        this.$message.error("请输入客户全称");
        return
      }
      if (!this.form.customer_identifier) {
        this.$message.error("请输入客户标识");
        return
      }
      if (this.customerId == undefined) {
        if (!this.form.password) {
          this.$message.error("请输入客户密钥");
          return
        }
      }
      if (this.form.qquid_parameter_name.length <= 0) {
        this.$message.error("请输入qquid对应参数");
        return
      }
      if (this.form.menu_parameter_name.length <= 0) {
        this.$message.error("请输入menu对应参数");
        return
      }
      if (this.form.complete_flag.length <= 0) {
        this.$message.error("请输入完成状态标识");
        return
      }
      if (this.form.recognize_flag.length <= 0) {
        this.$message.error("请输入甄别状态标识");
        return
      }
      if (this.form.quota_exhausted_flag.length <= 0) {
        this.$message.error("请输入配额满状态标识");
        return
      }
      if (!this.form.complete_callback_url) {
        this.$message.error("请输入C状态链接");
        return
      }
      if (!this.form.recognize_callback_url) {
        this.$message.error("请输入S状态链接");
        return
      }
      if (!this.form.quota_exhausted_callback_url) {
        this.$message.error("请输入Q状态链接");
        return
      }
      this.createCustomerData();
    },
    async createCustomerData() {
      this.loading = true;
      try {
        let params = {
          customer_name: this.form.customer_name,
          abbreviated_name: this.form.abbreviated_name,
          full_name: this.form.full_name,
          customer_identifier: this.form.customer_identifier,
          password: this.form.password,
          qquid_parameter_name: this.form.qquid_parameter_name,
          menu_parameter_name: this.form.menu_parameter_name,
          complete_flag: this.form.complete_flag,
          recognize_flag: this.form.recognize_flag,
          quota_exhausted_flag: this.form.quota_exhausted_flag,
          priority: this.form.priority,
          complete_callback_url: this.form.complete_callback_url,
          recognize_callback_url: this.form.recognize_callback_url,
          quota_exhausted_callback_url: this.form.quota_exhausted_callback_url
        };
        if (this.customerId == undefined) {
          await createCustomer(params);
          this.$message.success("客户添加成功");
        }else {
          params["customer_id"] = this.customerId;
          await updateCustomer(params);
          this.$message.success("客户更新成功");
        }
        this.loading = false;
        const that = this;
        setTimeout(() => {
          if (that.customerId == undefined) {
            that.$router.push({ name: "customer"});
          }else {
            that.$router.go(-1);
          }
        }, 500);
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
  }
};
</script>